@charset "utf-8";

// Theme skin
@import "basically-basic/themes/night";

@import "basically-basic";

body, .wrapper, .navicon-button, .sidebar-toggle-wrapper {
    background: transparent;
}

body {
    background: url("/assets/bg.jpg")  no-repeat center center fixed;

    /* Center and scale the image nicely */
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.intro > .inner, #main > .inner {
    background-color: rgba(0,0,0, 0.8);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;;
}